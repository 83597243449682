import * as React from "react";
import { useTheme, styled } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Rating from "@mui/material/Rating";

const CustomRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#333333", // Custom fill color
  },
  "& .MuiRating-iconHover": {
    color: "#555555", // Custom hover color (optional)
  },
});



const HomeCarousel = ({testimonials,clientDeatil}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = testimonials.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="flex flex-col gap-[80px] mx-[40px] mb-[112px]">
      <div className="flex justify-center items-center">
      <div className="flex justify-center flex-col align-middle items-center w-full md:w-2/3 ">
        <p className="text-[16px] font-semibold text-[#333333]">
          {clientDeatil[0]?.name}
        </p>
        <h1 className="text-[30px] sm:text-[48px] font-bold text-[#333333] text-center leading-[57px] pb-[16px]">
        {clientDeatil[0]?.header}
        </h1>
        <p className="text-[18px] font-normal text-center">{clientDeatil[0]?.detail}</p>
      </div>
      </div>
      <div className="flex items-center flex-col relative">
        <CustomRating
          name="size-medium"
          defaultValue={testimonials[activeStep].rating}
          className="mb-[32px]"
          readOnly
        />
        <h3 className="w-full sm:w-2/3 flex justify-center text-center text-[#333333] text-[24px] font-bold mb-[32px]">
          {testimonials[activeStep].text}
        </h3>
        <div className="flex flex-row gap-[20px]">
          <img
            className="w-[56px] h-[56px] rounded-full "
            src={testimonials[activeStep].image}
            alt={testimonials[activeStep].name}
          />
          <div className="flex flex-col">
            <p className="text-[#333333]">{testimonials[activeStep].name}</p>
            <p className="text-[#333333]">{testimonials[activeStep].title}</p>
          </div>
        </div>
        <div className="flex flex-row w-[355px] sm:w-full justify-between absolute items-center align-middle mt-28">
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            className="absolute "
          >
            {theme.direction === "rtl" ? (
              <img src="../assets/images/rightarrow.svg" alt="right" />
            ) : (
              <img src="../assets/images/leftarrow.svg" alt="left" />
            )}
          </Button>
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            className="absolute"
          >
            {theme.direction === "rtl" ? (
              <img src="../assets/images/leftarrow.svg" alt="left" />
            ) : (
              <img src="../assets/images/rightarrow.svg" alt="right" />
            )}
          </Button>
        </div>
        <div className="flex justify-center">
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className="mt-[48px]"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
