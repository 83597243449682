import React from 'react'
import AccordionTransition from './AccordionTransition'
import { Link } from 'react-router-dom'
import CustomAccordation from '../CustomAccordation'

const Faq = ({corouselData}) => {
  return (
    <div>
    <div className='mt-[110px]'>
        <h1 className='text-[48px] font-bold text-[#333333]'>FAQ</h1>
        <p className='mt-[16px] mb-[24px] text-[#333333]'>Have questions about our export services? Find answers to some of the most commonly asked questions below. If you need further assistance, feel free to contact us.</p>
        <Link to="/contact"><button className='bg-[#41B641] text-[#FFFFFF]  px-6 py-2 rounded-full mb-8'><h1>Contact Us</h1></button></Link>
    </div>
    <div className='mt-[80px] mb-[110px]'>
      <CustomAccordation data={corouselData}/>
    </div>
    </div>
  )
}

export default Faq