import React from 'react'

const projectDetails=[
    {image:"./assets/images/hand.svg",number:"200+",name:"Projects",detail:"Successfully delivered worldwide."},
    {image:"./assets/images/hand2.svg",number:"95%",name:"Satisfaction",detail:"Rate based on feedback and surveys."},
    {image:"./assets/images/co2.svg",number:"15,000",name:"Tons CO2",detail:"Footprints reduce annually."},
]

const TotalProjects = () => {
  return (
    <div className='flex flex-col justify-center items-center lg:flex-row md:justify-evenly bg-[#E9F3F2] mt-[60px] md:mt-[170px] mb-[60px]'>
        {projectDetails.map((data)=>(
            <div className='py-[40px] lg:py-[110px]'>
            <img src={data.image} alt=''/>
            <div className='flex flex-row items-baseline gap-2'>
            <h1 className='text-[#41B641] text-[48px] font-bold'>{data.number}</h1>
            <p className='text-[32px] font-medium '>{data.name}</p>
            </div>
            <p>{data.detail}</p>
        </div>
        ))}
    </div>
  )
}

export default TotalProjects