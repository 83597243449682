import React, { useState } from 'react';

const GalleryMainCard = ({ image, name, detail, data, width }) => {
  const [isHovered, setIsHovered] = useState(false);
  
  const cardContentStyle = {
    padding: name ? "20px" : "10px", // Adjusted padding
  };
  const cardMediaStyle = {
    height: image ? "60%" : "auto", // Let the height be auto
  };

  return (
    <div
      className='relative rounded-md'
      style={{ width , transition: "box-shadow 0.3s",
      boxShadow: isHovered ? "1px 10px 20px 20px rgba(0, 0, 0, 0.2)" : "none",}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={image || data.image}
        alt='img'
        className='h-[360px] w-full object-cover rounded-md'
      />
      <div className='absolute top-0 left-0 w-full h-full flex flex-col justify-end p-4 bg-opacity-50 text-white bg-black rounded-md'>
        <h2 className='text-[24px] mb-2 font-bold leading-[120%]'>{name || data.name}</h2>
        <p className='text-[20px] font-light'>{detail || data.detail}</p>
        <div className='flex flex-row mt-[24px] gap-[4px] items-center'>
          <p className='uppercase text-[14px] font-medium'>know more</p>
          <img
            src='../assets/images/direction.svg'
            alt='arrow'
            style={{ width:'24px', transition: 'width 0.3s' }}
          />
        </div>
      </div>
    </div>
  );
};

export default GalleryMainCard;
