import React from "react";
import TeamAbout from "./About/TeamAbout";
import AboutLocation from "./About/AboutLocation";
// import Image1 from '/assets/images/aboutusback.svg';
// import Image2 from '/assets/images/aboutusback.svg';
// import Image3 from '/assets/images/aboutusback.svg';

const About = () => {
  return (
    <div className="">
      <div
        className="bg-cover bg-center pt-[100px]"
        style={{ backgroundImage: `url(/assets/images/aboutusback.svg)` }}
      >
        <div className=" bg-opacity-50 py-16">
          <div className="w-full md:w-3/4 px-4 sm:px-6 md:px-[40px] 2xl:px-[120px]">
            <h1 className="text-white text-[34px] md:text-[48px] lg:text-[56px] font-bold mb-4 leading-[120%] md:leading-[120%]">
              Discover Our Commitment to Sustainability and Innovation
            </h1>
            <p className="text-gray-300 text-[16px] md:text-[18px] mb-8 font-normal md:leading-[150%]">
              At Teximass Singapore Pte Ltd, we are dedicated to driving
              sustainability and innovation in agriculture. Learn more about our
              journey and how we are making a global impact.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row px-[20px] sm:px-[30px] md:px-[40px] 2xl:px-[120px] gap-0 md:gap-[70px] items-center">
        <div className="w-full md:w-1/2 flex flex-col justify-center items-start">
          <p className="mt-[40px] md:mt-10 lg:mt-0 text-[16px] font-bold">About Us</p>
          <h2 className="text-[#333333] text-[48px] font-bold mb-[24px] mt-[16px] leading-[120%]">
            About Teximass
          </h2>
          <div className="flex flex-col md:flex-row">
            <div className="">
              <p className="text-[#333333] text-[18px] font-normal mb-4">
                Founded in 2013, Teximass Singapore Pte Ltd began as a small
                consultancy firm with a vision to revolutionize the agricultural
                industry. Over the years, we have grown into a leading provider
                of sustainable agricultural solutions, driven by our commitment
                to innovation, excellence, and sustainability.
              </p>
              <p className="text-[#333333] text-[18px] font-normal mb-4">
                At Teximass, we believe that sustainable farming is the key to a
                prosperous future. Our expert team of agronomists, engineers,
                and consultants work tirelessly to develop and implement
                cutting-edge technologies and practices that enhance
                productivity, reduce environmental impact, and promote long-term
                viability in the farming industry.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 my-[30px] md:my-[77px]">
          <img
            src={"/assets/images/tomato.svg"}
            alt="Agricultural machinery and baskets of produce"
            className="w-full h-auto mb-[24px]"
          />
        </div>
      </div>
      <TeamAbout />
      <AboutLocation />
    </div>
  );
};

export default About;
