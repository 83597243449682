import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Lightbox } from './Lightbox.js';
import { useMediaQuery } from '@mui/material';

const categories = [
  {
    title: "View all",
    description: "",
    images: [
    "../assets/images/gallery/gallery1.svg",
    "../assets/images/gallery/gallery2.svg",
    "../assets/images/gallery/gallery3.svg",
    "../assets/images/gallery/gallery4.svg",
    "../assets/images/gallery/gallery5.svg",
    "../assets/images/gallery/gallery6.svg",
    "../assets/images/gallery/gallery7.svg",
    "../assets/images/gallery/gallery8.svg",
    "../assets/images/gallery/gallery9.svg",
    "../assets/images/gallery/gallery10.svg",
    "../assets/images/gallery/gallery11.svg",
    "../assets/images/gallery/gallery12.svg",
    "../assets/images/gallery/gallery13.svg",
    "../assets/images/gallery/gallery14.svg",
    "../assets/images/gallery/gallery15.svg",
    "../assets/images/gallery/gallery16.svg",
    "../assets/images/gallery/gallery17.svg",
    "../assets/images/gallery/gallery18.svg",
    ]
  },
  {
    title: "Innovative Agriculture Practices",
    description: "Witness our efforts in transforming agriculture with groundbreaking techniques. From implementing precision farming tools to utilizing advanced irrigation methods, we focus on maximizing efficiency and sustainability. Explore how we integrate technology to boost productivity and ensure eco-friendly farming.",
    images: [
        "../assets/images/gallery/gallery1.svg",
        "../assets/images/gallery/gallery6.svg",
        "../assets/images/gallery/gallery7.svg",
        "../assets/images/gallery/gallery10.svg",
        "../assets/images/gallery/gallery11.svg",
        "../assets/images/gallery/gallery16.svg",
        "../assets/images/gallery/gallery17.svg",
    ]
  },
  {
    title: "Pioneering Carbon Credit Solutions",
    description: "",
    images: [
        "../assets/images/gallery/gallery11.svg",
        "../assets/images/gallery/gallery13.svg",
        "../assets/images/gallery/gallery14.svg",
        "../assets/images/gallery/gallery12.svg",
    ]
  },
  {
    title: "Advanced Tissue Culture Labs",
    description: "",
    images: [
        "../assets/images/gallery/gallery2.svg",
        "../assets/images/gallery/gallery4.svg",
        "../assets/images/gallery/gallery7.svg",
        "../assets/images/gallery/gallery10.svg",
        "../assets/images/gallery/gallery18.svg",
    ]
  },
  {
    title: "Sterile tissue Culture Clean Rooms",
    description: "",
    images: [
        "../assets/images/gallery/gallery15.svg",
        "../assets/images/gallery/gallery4.svg",
        "../assets/images/gallery/gallery3.svg",
        "../assets/images/gallery/gallery9.svg",
    ]
  },
];

export default function GalleryCategories() {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [lightboxImage, setLightboxImage] = React.useState('');
  const isMobile = useMediaQuery('(max-width:600px)');
  const isBelowMd = useMediaQuery('(max-width:960px)');
  const [expandedCategory, setExpandedCategory] = React.useState(null);

  const handleImageClick = (imageSrc) => {
    setLightboxImage(imageSrc);
    setLightboxOpen(true);
  };

  const handleCategoryClick = (index) => {
    if (isBelowMd) {
      setExpandedCategory(expandedCategory === index ? null : index);
    } else {
      setSelectedTab(index);
    }
  };
  
  return (
    <Box sx={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
      <Box className={isMobile ? 'w-full' : 'w-[40%] sm:w-[30%] pr-2'}>
        <p className='text-[14px] md:text-[18px] font-bold mb-[24px]'>
          Gallery Categories
        </p>
        {categories.map((category, index) => (
          <Box key={index}>
            <Box 
              sx={{ 
                cursor: 'pointer',
                bgcolor: (isBelowMd ? expandedCategory === index : selectedTab === index) ? '#e8f4f8' : 'transparent',
                p: 1,
              }}
              className="mb-2"
              onClick={() => handleCategoryClick(index)}
            >
              <p className='text-[11px] sm:text-[12px] md:text-[16px] '>
                {category.title}
              </p>
            </Box>
            {((isBelowMd && expandedCategory === index) || (!isBelowMd && selectedTab === index)) && category.description && (
              <p className="text-[12px] p-1 bg-[#e8f4f8] mb-2">{category.description}</p>
            )}
            {isBelowMd && expandedCategory === index && (
              <Grid container spacing={2}>
                {category.images.map((image, imgIndex) => (
                  <Grid item xs={12} sm={6} key={imgIndex}>
                    <Box sx={{
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover .view-text': {
                        opacity: 1,
                      },
                    }}>
                      <img
                        src={image}
                        alt={`Gallery image ${imgIndex + 1}`}
                        style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                        onClick={() => handleImageClick(image)}
                      />
                      <Typography
                        sx={{
                          position: 'absolute',
                          bottom: 10,
                          right: 10,
                          color: 'white',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          padding: '2px 5px',
                          borderRadius: '3px',
                          fontSize: '0.8rem',
                        }}
                      >
                        View
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        ))}
      </Box>
      {!isBelowMd && (
        <Box className='w-[60%] sm:w-[70%]'>
          <Grid container spacing={2}>
            {categories[selectedTab].images.map((image, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Box sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover .view-text': {
                    opacity: 1,
                  },
                }}>
                  <img
                    src={image}
                    alt={`Gallery image ${index + 1}`}
                    style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                    onClick={() => handleImageClick(image)}
                  />
                  <Typography
                    sx={{
                      position: 'absolute',
                      bottom: 10,
                      right: 10,
                      color: 'white',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      padding: '2px 5px',
                      borderRadius: '3px',
                      fontSize: '0.8rem',
                    }}
                  >
                    View
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Lightbox
        isOpen={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        imageSrc={lightboxImage}
      />
    </Box>
  );
}