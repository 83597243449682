import React from "react";
import { Link } from "react-router-dom";

const CallToAction = ({ data }) => {
  const datas = data[0];
  return (
    <div className="py-[110px] bg-[#E9F3F2] flex w-full px-[20px] sm:px-[30px] md:px-[40px] 2xl:px-[120px] ">
      <div className="lg:w-2/3">
        <p className="text-[16px] font-semibold">{datas?.name}</p>
        <h1 className="text-[24px] md:text-[48px] font-bold text-[#333333] py-[16px] leading-[120%] md:leading-[55px]">
          {datas?.header}
        </h1>
        <p className="text-[18px] font-normal text-[#333333] mb-[16px]">
          {datas?.detail}
        </p>
        <Link to="/contact">
          <button className="bg-[#41B641] text-[#FFFFFF]  px-6 py-2 rounded-full mb-8">
            <h1>{datas?.button}</h1>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default CallToAction;
