import React from "react";

const team = [
  {
    photo: "../assets/images/gallery/srinivas.svg",
    name: "Srinivas Rao Pulavarti",
    position: "CEO",
    detail:
      "14+ years of combined agribusiness experience in Africa handling several agri projects and carbon Environmental & Social Impact projects",
  },
  {
    photo: "../assets/images/gallery/sriman.svg",
    name: "Sriman NVCK",
    position: "Development Director",
    detail:
      "8 years into Decarbonization Services SP Power Nanyang Technology University o DSTA 7 years of Sustainability consulting, LEED AP(USGBC) o JP Morgon o Oracle o Microsrosoft o Barclays",
  },
  {
    photo: "../assets/images/gallery/michael.png",
    name: "Punnam Veer Reddy",
    position: "Scientist Biotechnologie",
    detail:
      "Avec specialisation en biotechnolgie vegetalea Tamil Nadu, INDE Universite d`agriculture ",
  },
  {
    photo: "../assets/images/gallery/emma.png",
    name: "Nityanandan.V",
    position: "Project Head-Green house and nursery serup",
    detail:
      "M.SC.(Botany)- Bangalore University Establishment of Tissue culture laboratory for production of Micro tubers in SriLanka and involved in the extraction of Pine oil from Pine wood plantations",
  },
  {
    photo: "../assets/images/gallery/emma2.png",
    name: "A.Ravi Kumar Naidu",
    position: "Micro irrigation",
    detail:
      "Over 35 years experience beginning as a micro drip irrigation engineer working up to AGM of MNC in netafim Micro irrigation industry having team of over 200",
  },
];

const TeamAbout = () => {
  return (
    <div className="bg-[#E9F3F2] py-[112px] px-[20px] sm:px-[30px] md:px-[40px]">
      <div className="mb-[80px]">
        <p className="mb-[16px] text-[16px] font-semibold text-[#141414]">
          Meet Our Team
        </p>
        <h1 className="mb-[24px] text-[48px] font-bold text-[#141414]">
          The Minds Behind Teximass
        </h1>
        <p className="text-[18px] font-normal text-[#141414]">
          Our team of passionate experts drives innovation in sustainable
          agriculture. We combine diverse expertise to create impactful
          solutions for the farming industry and environment.
        </p>
      </div>
      <div className="overflow-x-scroll scrollbar-hide">
        <div className="flex gap-4 pb-4" style={{ width: 'max-content' }}>
          {team.map((data, index) => (
            <div key={index} className="w-[276px] flex-shrink-0">
              <div className="justify-center flex mb-4">
                <img src={data.photo} alt={`${data.name}'s photo`} />
              </div>
              <p className="text-[20px] font-semibold text-[#333333]">{data.name}</p>
              <p className="text-[18px] font-normal text-[#333333] mb-4 leading-6">{data.position}</p>
              <p className="text-[16px] font-normal text-[#333333]">{data.detail}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamAbout;