import React from "react";
import FirstService from "../../component/services/FirstService";
import Keyproduct from "../../component/services/Keyproduct";
import ServiceCard from "../../component/services/ServiceCard";
import Corousel from "../Corousel";
import Faq from "../../component/services/Faq";
import CallToAction from "../../component/services/CallToAction";
const data = [
  {
    firstName: "Global Talent Recruitment for Diverse Markets",
    firstDetail:
      "Connecting Professionals with Opportunities Across the Globe",
    firstbutton: "Find Your Opportunity",
    firstlogodetail:
      "",
    logo1: "",
    logo2: "",
    logo3: "",
    firstImage: "../assets/images/services/placement1.svg",
    secondName: "Expert Recruitment for International Placements",
    secondDetail:
      "At Teximass Singapore Private Limited, we specialize in recruiting top-tier professionals for placements in various regions around the world. Our expertise lies in sourcing engineers, professors, agronomists, and business analysis professionals to meet the growing demands of markets in the Gulf, Middle East, Africa, Europe, Canada, Australia, and the United Kingdom.",
    secondbutton: "Request a Quote",
    secondhead: "",
    secondImage: "../assets/images/services/placement2.svg",
  },
];

const keyData = [
  {
    name: "Our Training Programs",
    detail:
      "Teximass Singapore Private Limited offers tailored training solutions to meet diverse client needs. Our expert trainers deliver customized programs using the latest technology for impactful learning, both onsite and online, enhancing skills and driving success.",
    header: "",
    button: "Request a Quote",
  },
];

const serviceCardData = [
  {
    logo: "../assets/images/services/CustomNeeds.svg",
    name: " Tissue Culture Plants",
    detail:
      "We develop tailored training programs that align with your organization’s specific goals and objectives. Our process includes designing a comprehensive curriculum, selecting effective training methodologies, and creating detailed course materials to ensure a successful learning experience.",
    read: "",
  },
  {
    logo: "../assets/images/services/EngagingExperts.svg",
    name: "Horticulture",
    detail:
      "Our expert trainers conduct interactive workshops, seminars, and training sessions either onsite or through virtual platforms. We utilize a mix of lectures, discussions, activities, and simulations to create a dynamic and engaging learning environment.",
    read: "",
  },
  {
    logo: "../assets/images/services/FocusedLevels.svg",
    name: "Seed",
    detail:
      "Our training programs focus on enhancing specific skills and competencies needed for professional success. We offer training for all organizational levels, from frontline employees to executives, ensuring comprehensive development across your team.",
    read: "",
  },
  {
    logo: "../assets/images/services/ExpertGaps.svg",
    name: " Seedling",
    detail:
      "Our consulting services help assess your organizational needs, identify skill gaps, and recommend tailored training solutions. Our holistic approach ensures your team receives the most relevant and effective training to meet your business objectives.",
    read: "",
  },
  {
    logo: "../assets/images/services/AdvancedLearning.svg",
    name: "Agro forestry",
    detail:
      "We leverage cutting-edge technology to deliver training, including e-learning platforms, virtual classrooms, and interactive tools. This enhances engagement, accessibility, and the overall effectiveness of the training, making it more convenient and impactful.",
    read: "",
  },
];

const testimonials = [
  {
    rating: 5,
    text: "The training provided by Teximass was transformative for our team. The customized programs and expert delivery made a significant difference in our performance.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
];

const clientDeatil = [
  {
    name: "Client Testimonials",
    header: "Hear From Our Satisfied Clients",
    detail:
      "Discover how our training services have helped other organizations achieve their goals. Read testimonials from our satisfied clients to see the impact of our customized training programs.",
  },
];

const corouselData = [
  {
    name: "What types of training programs do you offer?",
    detail:
      "Answer: Our training sessions are delivered through interactive workshops, seminars, and virtual platforms. We provide both onsite and online training to suit your preferences.",
  },
  {
    name: "How are the training sessions delivered?",
    detail:
      "Answer: Our training sessions are delivered through interactive workshops, seminars, and virtual platforms. We provide both onsite and online training to suit your preferences.",
  },
  {
    name: "Who conducts the training sessions?",
    detail:
      "Answer: We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    name: "Can you help us identify skill gaps and recommend training solutions?",
    detail:
      "Answer: We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    name: "How do you measure the effectiveness of your training programs?",
    detail:
      "Answer: We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
];

const callAction= [{
  name:"Partner with Teximass for Expert International Placements",header:"Ready to Find the Best Talent?",detail:"Take the next step in building a strong and capable team. Contact us today to learn more about our international placement services and how we can support your recruitment needs.",button:"Contact Us Today"
}]

const Placement = () => {
  return (
    <>
      <div className=" px-[20px] md:px-[40px]">
        <FirstService data={data} />
        {/* <Keyproduct data={keyData} /> */}
        {/* <ServiceCard data={serviceCardData} /> */}
        {/* <Corousel testimonials={testimonials} clientDeatil={clientDeatil} /> */}
        {/* <Faq corouselData={corouselData} /> */}
      </div>
      <CallToAction data={callAction}/>
    </>
  );
};

export default Placement;
