import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./component/Navbar"
import Footer from "./component/Footer"
import Home from "./pages/Home"
import About from "./pages/About"
import Gallery from "./pages/Gallery"
import Contact from "./pages/Contact"
import Export from "./pages/services/Export"
import Business from "./pages/services/Business"
import Finance from "./pages/services/Finance"
import Nursery from "./pages/services/Nursery"
import Placement from "./pages/services/Placement"
import Training from "./pages/services/Training"

const App=()=> {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />} ></Route>
          <Route path="/gallery" element={<Gallery />} ></Route>
          <Route path="/contact" element={<Contact />} ></Route>
          <Route path="/export" element={<Export />} ></Route>
          <Route path="/training" element={<Training />} ></Route>
          <Route path="/business" element={<Business />} ></Route>
          <Route path="/nursery" element={<Nursery />} ></Route>
          <Route path="/placement" element={<Placement />} ></Route>
          <Route path="/finance" element={<Finance />} ></Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
