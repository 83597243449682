import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";


const CustomAccordation = ({data}) => {
  const [selected, setSelected] = useState(null);

  const handleShow = (name) => {
    setSelected((prevSelected) => (prevSelected === name ? null : name));
  };

  return (
    <div className="flex flex-col">
      {data.map((item, index) => (
        <div key={index} className="flex flex-col border-b-2 p-4">
          <div className="flex flex-row justify-between" onClick={() => handleShow(item.que)}>
            <p className="font-bold">{item.que}</p>
            {selected === item.que ? <CloseIcon /> : <AddIcon />}
          </div>
          {selected === item.que ? <p className="mt-[8px]"><span className="font-bold">Answer: </span>{item.ans}</p> : null}
        </div>
      ))}
    </div>
  );
};

export default CustomAccordation;
