import React from 'react'
import HomeServices from './services/HomeServices'
import Business from './services/Business'
import ImpactGallery from './ImpactGallery'
import Corousel from './Corousel'
import HomeNews from './HomeNews'

const testimonials = [
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
];

const clientDeatil=[{
  name:"Client Testimonials",header:"Real Success Stories from Our Satisfied Clients",detail:""
}]

const Home = () => {
  return (
  <div className='flex flex-col '>
    <div className='relative lg:h-screen w-full'>
    <img src='/assets/images/homeCover.svg' alt='' className='absolute inset-0 w-full mt-[100px] md:mt-0 h-max object-cover'/>
    <div className='relative z-10 w-full lg:w-2/3 flex flex-col items-start justify-center text-black md:text-white px-[20px] mt-[110px] md:mt-0 sm:px-[30px] md:px-[40px] 2xl:px-[120px] pt-[280px] md:pt-[180px] lg:pt-[250px]'>
      <h1 className='text-[32px] md:text-[54px] xl:text-[64px] font-bold mb-[16px] sm:mb-[25px] md:mb-[32px] leading-[120%] md:leading-[64px]'>Driving Sustainability and Innovation in Agriculture</h1>
      <p className='text-[16px] lg:text-[18px] mb-[12px] sm:mb-[25px] md:mb-[32px] md:leading-[150%] font-normal'>Providing expert consultancy and cutting-edge solutions in carbon consulting, tissue culture labs, sugar factory optimization, irrigation systems, and greenhouse construction to foster a sustainable future.</p>
      <h1 className='bg-[#41B641] text-[#FFFFFF] rounded-full py-[3px] md:py-[7px] px-[18px] md:px-[24px] mb-[32px] text-[16px] lg:text-[20px]'>Discover Our Services</h1>
    </div>
    </div>
    <div className="relative mt-[10px] md:mt-[150px] lg:mt-[260px]">
        <HomeServices />
        <ImpactGallery/>
        <Corousel testimonials={testimonials} clientDeatil={clientDeatil}/>
        <HomeNews/>
    </div>

  </div>
  )
}

export default Home