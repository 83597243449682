import React, { useState } from "react";

const news = [
  {
    date: "May",
    name: "Teximass Partners with Leading Teximass Firm to Revolutionize Greenhouse Construction",
    detail:
      "Teximass Singapore Pte Ltd has announced a strategic partnership with a leading Teximass firm to innovate and enhance greenhouse construction. This collaboration aims to integrate advanced technologies to improve energy efficiency and crop yield in greenhouses globally. The project is expected to set new standards in sustainable agriculture.",
  },
  {
    date: "Mar",
    name: "Sustainable Farming Practices: Teximass Launches New Training Program",
    detail:
      "Teximass Singapore Pte Ltd has launched an extensive training program focused on sustainable farming practices. The program offers hands-on training and expert guidance to farmers and agribusiness professionals, aiming to promote eco-friendly techniques and boost productivity. Participants will gain valuable insights into innovative farming methods.",
  },
  {
    date: "Jan",
    name: "Teximass Completes Major Irrigation Project in Africa",
    detail:
      "Teximass Singapore Pte Ltd has successfully completed a significant irrigation project in Africa, aimed at transforming arid land into productive farmland. The project involved the installation of advanced irrigation systems, which are expected to increase agricultural output and improve water efficiency for local farmers.",
  },
];

const HomeNews = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="py-[60px] md:py-[112px] bg-[#E9F3F2] px-[20px] sm:px-[30px] md:px-[40px] 2xl:px-[120px]">
      <h1 className="pb-[60px] md:pb-[80px] text-[48px] font-bold">News</h1>
      {news.map((data, index) => (
        <div key={index}>
          <hr className="" style={{ border: "1px solid black" }} />
          <div className="hidden md:flex pt-[40px]">
            <div className="w-6/12 md:w-2/12">
              <h3 className="text-[22px] md:text-[32px] font-normal leading-10">
                {data?.date}
              </h3>
              <h3 className="text-[22px] md:text-[32px] font-normal leading-10">
                2024
              </h3>
            </div>
            <div className="w-6/12 md:w-4/12">
              <h4 className="text-[16px] md:text-[24px] font-bold leading-8">
                {data?.name}
              </h4>
            </div>
            <div className=" md:w-6/12">
              <p className="text-[14px] md:text-[16px] font-normal leading-6">
                {data?.detail}
              </p>
              <div className="flex align-bottom items-center gap-[4px] pt-[40px] pb-[32px]">
                <p className="text-[#41B641] uppercase">read only</p>
                <img
                  src="./assets/images/direction.svg"
                  alt="arrow"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{
                    width: hoveredIndex === index ? "28px" : "24px",
                    height: "24px",
                    transition: "width 0.3s",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex md:hidden pt-[40px]">
            <div className="flex mb-[24px]">
              <div className="w-3/12 ">
                <h3 className="text-[22px] md:text-[32px] font-normal leading-6">
                  {data?.date}
                </h3>
                <h3 className="text-[22px] md:text-[32px] font-normal leading-6">
                  2024
                </h3>
              </div>
              <div className="w-9/12 ">
                <h4 className="text-[20px] md:text-[24px] font-bold leading-6">
                  {data?.name}
                </h4>
              </div>
            </div>
            </div>
            <div className="md:hidden w-full">
              <p className="text-[14px] md:text-[16px] font-normal leading-6">
                {data.detail}
              </p>
              <div className="flex align-bottom items-center gap-[4px] pt-[24px] pb-[20px]">
                <p className="text-[#41B641] uppercase">read only</p>
                <img
                  src="./assets/images/direction.svg"
                  alt="arrow"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{
                    width: hoveredIndex === index ? "28px" : "24px",
                    transition: "width 0.3s",
                  }}
                />
              </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeNews;
