import React from 'react'

const Keyproduct = ({data}) => {
    const datas= data[0]
  return (
    <div className='mt-[110px] w-full md:w-2/3 mb-[80px]'>
        <p className='mb-4 text-[#141414] text-[16px] font-semibold'>{datas?.header}</p>
        <h2 className='text-[34px] sm:text-[48px] font-bold leading-[120%] md:leading-[57px]'>{datas?.name}</h2>
        <p className='text-[18px] font-normal mb-6 mt-4'>{datas?.detail}</p>
        <button className='bg-[#41B641] text-[#FFFFFF] px-6 py-2 rounded-full'><h1>{datas?.button}</h1></button>
    </div>
  )
}

export default Keyproduct