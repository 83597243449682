import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export const Lightbox=({ isOpen, onClose, imageSrc })=> {
    if (!isOpen) return null;
  
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
        onClick={onClose}
      >
        <img
          src={imageSrc}
          alt="Full size"
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
        <Typography
          sx={{
            position: 'absolute',
            bottom: 20,
            right: 20,
            color: 'white',
            cursor: 'pointer',
          }}
          onClick={onClose}
        >
          Close
        </Typography>
      </Box>
    );
  }