import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const ServiceCard = ({ name, image, details, data }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Adjusting the styles for dynamic height
  const cardContentStyle = {
    padding: name ? "16px" : "16px", // Adjusted padding
  };
  const cardMediaStyle = {
    height: image ? "50%" : "auto", // Let the height be auto
  };

  return (
    <Card
      style={{
        borderRadius: "8px",
        overflow: "hidden",
        width: "100%",
        border: "1px solid #41B641",
        transition: "box-shadow 0.3s",
        boxShadow: isHovered ? "1px 10px 20px 10px rgba(0, 0, 0, 0.2)" : "none",
      }}
      className="rounded-lg overflow-hidden w-full border border-[#41B641] "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardMedia
        component="img"
        image={image || data.image}
        alt={name || data.name}
        style={cardMediaStyle}
        className="w-full object-cover"
      />
      <div className="flex bg-[#E9F3F2] h-full">
      <div className="p-[16px] bg-[#E9F3F2] h-full" style={cardContentStyle}>
        <p className="text-[24px] font-semibold mb-2 leading-8">
          {name || data.name}
        </p>
        <p className="text-[16px] font-light leading-5 mb-[16px]">
          {details || data.details}
        </p>
        <div className="flex items-center mb-2 gap-[4px]">
          <button className="text-[#41B641] uppercase text-[16px] font-medium">
            Know More
          </button>
          <img src="./assets/images/direction.svg" alt="direction" />
        </div>
      </div>
      </div>
    </Card>
  );
};

export default ServiceCard;
