import React from "react";
import FirstService from "../../component/services/FirstService";
import Keyproduct from "../../component/services/Keyproduct";
import ServiceCard from "../../component/services/ServiceCard";
import Corousel from "../Corousel";
import Faq from "../../component/services/Faq";
import CallToAction from "../../component/services/CallToAction";
const data = [
  {
    firstName: "Advanced Plant Propagation for Horticultural Excellence",
    firstDetail:
      "Superior Quality Mother Plants for Successful Horticulture",
    firstbutton: "Discover Our Techniques",
    firstlogodetail:
      "",
    logo1: "",
    logo2: "",
    logo3: "",
    firstImage: "../assets/images/services/hightech1.svg",
    secondName: "Ensuring Success Through Superior Plant Propagation Techniques",
    secondDetail:
      "Nursery basically deals with plant propagation techniques. The planting materials for horticulture crops are raised from seeds or vegetative parts. Most of the perennials are propagated through vegetative means while annuals through seeds. Hence, the role of the mother block has become very important. The success of the nursery depends upon quality and truthfulness of the mother plants.",
    secondbutton: "Request a Quote",
    secondhead: "",
    secondImage: "../assets/images/services/hightech2.svg",
  },
];

const keyData = [
  {
    name: "Our Training Programs",
    detail:
      "Teximass Singapore Private Limited offers tailored training solutions to meet diverse client needs. Our expert trainers deliver customized programs using the latest technology for impactful learning, both onsite and online, enhancing skills and driving success.",
    header: "",
    button: "Request a Quote",
  },
];

const serviceCardData = [
  {
    logo: "../assets/images/services/CustomNeeds.svg",
    name: " Tissue Culture Plants",
    detail:
      "We develop tailored training programs that align with your organization’s specific goals and objectives. Our process includes designing a comprehensive curriculum, selecting effective training methodologies, and creating detailed course materials to ensure a successful learning experience.",
    read: "",
  },
  {
    logo: "../assets/images/services/EngagingExperts.svg",
    name: "Horticulture",
    detail:
      "Our expert trainers conduct interactive workshops, seminars, and training sessions either onsite or through virtual platforms. We utilize a mix of lectures, discussions, activities, and simulations to create a dynamic and engaging learning environment.",
    read: "",
  },
  {
    logo: "../assets/images/services/FocusedLevels.svg",
    name: "Seed",
    detail:
      "Our training programs focus on enhancing specific skills and competencies needed for professional success. We offer training for all organizational levels, from frontline employees to executives, ensuring comprehensive development across your team.",
    read: "",
  },
  {
    logo: "../assets/images/services/ExpertGaps.svg",
    name: " Seedling",
    detail:
      "Our consulting services help assess your organizational needs, identify skill gaps, and recommend tailored training solutions. Our holistic approach ensures your team receives the most relevant and effective training to meet your business objectives.",
    read: "",
  },
  {
    logo: "../assets/images/services/AdvancedLearning.svg",
    name: "Agro forestry",
    detail:
      "We leverage cutting-edge technology to deliver training, including e-learning platforms, virtual classrooms, and interactive tools. This enhances engagement, accessibility, and the overall effectiveness of the training, making it more convenient and impactful.",
    read: "",
  },
];

const testimonials = [
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
];

const clientDeatil = [
  {
    name: "Client Testimonials",
    header: "Hear From Our Satisfied Clients",
    detail:
      "Discover how our training services have helped other organizations achieve their goals. Read testimonials from our satisfied clients to see the impact of our customized training programs.",
  },
];

const corouselData = [
  {
    name: "What types of training programs do you offer?",
    detail:
      "Answer: Our training sessions are delivered through interactive workshops, seminars, and virtual platforms. We provide both onsite and online training to suit your preferences.",
  },
  {
    name: "How are the training sessions delivered?",
    detail:
      "Answer: Our training sessions are delivered through interactive workshops, seminars, and virtual platforms. We provide both onsite and online training to suit your preferences.",
  },
  {
    name: "Who conducts the training sessions?",
    detail:
      "Answer: We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    name: "Can you help us identify skill gaps and recommend training solutions?",
    detail:
      "Answer: We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    name: "How do you measure the effectiveness of your training programs?",
    detail:
      "Answer: We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
];

const callAction= [{
  name:"Partner with Teximass for Advanced Horticultural Solutions",header:"Ready to Cultivate Success with Superior Plant Propagation?",detail:"Discover how our advanced plant propagation techniques can enhance your horticultural success. With a focus on quality and innovation, our Hi-Tech Nursery provides the best planting materials for your needs. Contact us today to learn more about our services and how we can support your horticultural projects.",button:"Contact Us Today"
}]

const Nursery = () => {
  return (
    <>
      <div className="px-[20px] md:px-[40px]">
        <FirstService data={data} />
        <Keyproduct data={keyData} />
        <ServiceCard data={serviceCardData} />
        {/* <Corousel testimonials={testimonials} clientDeatil={clientDeatil} /> */}
        {/* <Faq corouselData={corouselData} /> */}
      </div>
      <CallToAction data={callAction}/>
    </>
  );
};

export default Nursery;
