import React from "react";
import FirstService from "../../component/services/FirstService";
import Keyproduct from "../../component/services/Keyproduct";
import ServiceCard from "../../component/services/ServiceCard";
import Corousel from "../Corousel";
import Faq from "../../component/services/Faq";
import CallToAction from "../../component/services/CallToAction";
const data = [
  {
    firstName: "Optimize and Expand Your Global Business",
    firstDetail:
      "Expert Consulting for International Operations and Expansion",
    firstbutton: "Get Expert Advice",
    firstlogodetail:
      "",
    logo1: "",
    logo2: "",
    logo3: "",
    firstImage: "../assets/images/services/international1.svg",
    secondName: "Why Choose Our International Business Management Consulting?",
    secondDetail:
      "At Teximass Singapore Private Limited, our International Business Management Consulting services are designed to help you optimize your global operations and explore new expansion opportunities. Our experienced consultants provide valuable insights, fresh perspectives, and practical solutions to help you make informed decisions and achieve your business goals.",
    secondbutton: "Request a Quote",
    secondhead: "",
    secondImage: "../assets/images/services/training2.svg",
  },
];

const keyData = [
  {
    name: "Our Training Programs",
    detail:
      "Teximass Singapore Private Limited offers tailored training solutions to meet diverse client needs. Our expert trainers deliver customized programs using the latest technology for impactful learning, both onsite and online, enhancing skills and driving success.",
    header: "",
    button: "Request a Quote",
  },
];

const serviceCardData = [
  {
    logo: "../assets/images/services/CustomNeeds.svg",
    name: "Custom Training Programs to Meet Your Needs",
    detail:
      "We develop tailored training programs that align with your organization’s specific goals and objectives. Our process includes designing a comprehensive curriculum, selecting effective training methodologies, and creating detailed course materials to ensure a successful learning experience.",
    read: "",
  },
  {
    logo: "../assets/images/services/EngagingExperts.svg",
    name: "Engaging Training Delivered by Experts",
    detail:
      "Our expert trainers conduct interactive workshops, seminars, and training sessions either onsite or through virtual platforms. We utilize a mix of lectures, discussions, activities, and simulations to create a dynamic and engaging learning environment.",
    read: "",
  },
  {
    logo: "../assets/images/services/FocusedLevels.svg",
    name: "Focused Skill Development for All Levels",
    detail:
      "Our training programs focus on enhancing specific skills and competencies needed for professional success. We offer training for all organizational levels, from frontline employees to executives, ensuring comprehensive development across your team.",
    read: "",
  },
  {
    logo: "../assets/images/services/ExpertGaps.svg",
    name: "Expert Consulting to Identify and Bridge Skill Gaps",
    detail:
      "Our consulting services help assess your organizational needs, identify skill gaps, and recommend tailored training solutions. Our holistic approach ensures your team receives the most relevant and effective training to meet your business objectives.",
    read: "",
  },
  {
    logo: "../assets/images/services/AdvancedLearning.svg",
    name: "Advanced Technology for Modern Learning",
    detail:
      "We leverage cutting-edge technology to deliver training, including e-learning platforms, virtual classrooms, and interactive tools. This enhances engagement, accessibility, and the overall effectiveness of the training, making it more convenient and impactful.",
    read: "",
  },
  {
    logo: "../assets/images/services/ContinuousEvaluation.svg",
    name: "Continuous Improvement Through Evaluation",
    detail:
      "We measure the effectiveness of our training programs through detailed participant feedback, assessments, and post-training evaluations. Our follow-up support includes additional coaching and resources to reinforce learning outcomes and ensure lasting benefits for your organization.",
    read: "",
  },
];

const testimonials = [
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
];

const clientDeatil = [
  {
    name: "Client Testimonials",
    header: "Proven Success in International Business Consulting",
    detail:
      "Discover how our consulting services have helped other businesses achieve their international goals. Read success stories from our clients and see the impact of our tailored solutions.",
  },
];

const corouselData = [
  {
    que: "What services do you offer in international business management consulting?",
    ans:
      "Our consultants analyze your current operations and identify areas for improvement. We implement strategies to streamline processes, reduce costs, and enhance overall efficiency and effectiveness.",
  },
  {
    que: "How can your consulting services help optimize my international operations?",
    ans:
      "Our consultants analyze your current operations and identify areas for improvement. We implement strategies to streamline processes, reduce costs, and enhance overall efficiency and effectiveness.",
  },
  {
    que: "What kind of support do you provide for market expansion?",
    ans:
      "We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    que: "How do you conduct due diligence for international business expansion?",
    ans:
      "We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    que: "Who will be providing the consulting services?",
    ans:
      "We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
];

const callAction= [{
  name:"Partner with Teximass for Expert International Business Consulting",header:"Ready to Take Your Business Global?",detail:"Take the next step in optimizing and expanding your international business. Contact us today to learn more about our consulting services and how we can support your global success.",button:"Contact Us Today"
}]

const Business = () => {
  return (
    <>
      <div className="px-[20px] md:px-[40px]">
        <FirstService data={data} />
        {/* <Keyproduct data={keyData} /> */}
        {/* <ServiceCard data={serviceCardData} /> */}
        <Corousel testimonials={testimonials} clientDeatil={clientDeatil} />
        <Faq corouselData={corouselData} />
      </div>
      <CallToAction data={callAction}/>
    </>
  );
};

export default Business;
