import { Grid } from "@mui/material";
import React from "react";

const ServiceCard = ({ data }) => {
  return (
    <div className="mb-[110px]">
      <Grid container spacing={4}>
        {data.map((datas, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index} className="flex">
            <div className="p-6 rounded-lg bg-[#E9F3F2]">
              <img src={datas?.logo} alt="" />
              <h4 className="leading-7 text-[24px] font-semibold pt-[16px]">
                {datas?.name}
              </h4>
              <h4 className="leading-7 text-[24px] font-semibold pb-[12px]">
                {datas?.name2}
              </h4>
              <p className="leading-6">{datas?.detail}</p>
              <p className="text-[#41B641] underline">{datas?.read}</p>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ServiceCard;
