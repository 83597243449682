import React, { useRef, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import '../App.css'; 

const serviceSubMenu = [
  { title: 'Export', path: '/export' },
  { title: 'Training', path: '/training' },
  { title: 'International Business Management Consulting', path: '/business' },
  { title: 'High Tech Nursery', path: '/nursery' },
  { title: 'International Placements', path: '/placement' },
  { title: 'Import / Export Project Finance Support', path: '/finance' },
];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [menuToggle, setMenuToggle] = useState(false);
  const [mobileServicesOpen, setMobileServicesOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isTransparent, setIsTransparent] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrolledDown = currentScrollPos > 100; // 100px threshold
      
      setVisible((prevScrollPos > currentScrollPos) || currentScrollPos < 10);
      setIsTransparent(!isScrolledDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuOpen && !event.target.closest('#menu-appbar')) {
        handleCloseNavMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [mobileMenuOpen]);

  useEffect(() => {
    const handleMouseLeave = (event) => {
      if (window.innerWidth > 768) { // Adjust this breakpoint as needed
        if (!menuRef.current?.contains(event.target)) {
          setMenuToggle(false);
        }
      }
    };

    document.addEventListener('mouseover', handleMouseLeave);

    return () => {
      document.removeEventListener('mouseover', handleMouseLeave);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    event.stopPropagation();
    setAnchorElNav(event.currentTarget);
    setMobileMenuOpen(true);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setMobileMenuOpen(false);
    setMobileServicesOpen(false);
    setMenuToggle(false);
  };

  const handleService = () => {
    if (window.innerWidth > 768) { // Adjust this breakpoint as needed
      setMenuToggle(true);
    }
  };

  const handleMobileServicesToggle = (event) => {
    event.stopPropagation();
    setMobileServicesOpen(prev => !prev);
  };

  const handleLinkClick = (event, path) => {
    event.preventDefault();
    event.stopPropagation();
    console.log(`Link clicked: ${path}`);
    handleCloseNavMenu();
    window.location.assign(path);
  };

  return (
    <AppBar 
      position="fixed" 
      className='h-[100px] flex justify-center' 
      style={{ 
        background: isTransparent ? "rgba(255, 250, 238, 0.8)" : "#FFFAEE", 
        position: "fixed", 
        zIndex: 50,
        transform: visible ? 'translateY(0)' : 'translateY(-100%)',
        transition: 'transform 0.3s ease, background-color 0.3s ease'
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters className='justify-between mx-4 2xl:mx-6'>
          <div>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                background: 'transparent',
              }}
            >
              <img src="../assets/images/Logo.svg" alt="Logo" style={{ width: 'auto', height: '56px' }} />
            </Typography>
          </div>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
           <img src="../assets/images/Logo.svg" alt="Logo" style={{ width: 'auto', height: '56px' }} />
          </Typography>
          <div>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="gap-5">
               <a
                href="/about"
                onClick={(e) => handleLinkClick(e, '/about')}
                className='hover-underlines text-[#141414]'
              >
                About Us
              </a>
              <div ref={menuRef}>
                <p
                  sx={{ my: 2, display: 'block' }} 
                  className='hover-underlines text-[#141414] font-light'
                  onMouseEnter={handleService}
                >
                  Services<ArrowDropDownIcon className='flex align-top'/>
                </p>
                {menuToggle && 
                 <div className='flex flex-col absolute bg-[#FFFFFF] mt-1 p-[12px] shadow-2xl z-50 '>
                  {serviceSubMenu.map((item) => (
                    <a key={item.path} href={item.path} onClick={(e) => handleLinkClick(e, item.path)}>
                      <h1 className='hover:bg-[#F0F0F0] p-1 text-[#141414]'>{item.title}</h1>
                    </a>
                  ))}
                 </div>
                }
              </div>
                <a
                href="/gallery"
                onClick={(e) => handleLinkClick(e, '/gallery')}
                className='hover-underlines text-[#141414]'
              >
                Gallery
              </a>
               <a
                href="/contact"
                onClick={(e) => handleLinkClick(e, '/contact')}
                className='hover-underlines text-[#141414]'
              >
                Contact Us
              </a>
            </Box>
          </div>
         
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={mobileMenuOpen}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiPaper-root': {
                  width: '100%',
                  height: '100vh',
                  maxWidth: '100%',
                  top: '0 !important',
                  left: '0 !important',
                  right: '0 !important',
                  position: 'fixed',
                  overflowY: 'auto',
                  transition: 'transform 0.3s ease-in-out',
                  transform: mobileMenuOpen ? 'translateX(0)' : 'translateX(100%)',
                },
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                padding: '16px',
                backgroundColor: '#FFFAEE'
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center', 
                  marginBottom: '15px' 
                }}>
                  <a href="/"><img src="../assets/images/Logo.svg" alt="Logo" style={{ width: 'auto', height: '56px' }} /></a>
                  <IconButton
                    size="large"
                    aria-label="close menu"
                    onClick={handleCloseNavMenu}
                    color="inherit"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <MenuItem onClick={(e) => handleLinkClick(e, '/about')}>
                    <p className='font-medium'>About Us</p>
                  </MenuItem>
                  <MenuItem onClick={handleMobileServicesToggle}>
                    <p className='font-medium'>Services</p>
                    {mobileServicesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </MenuItem>
                  {mobileServicesOpen && serviceSubMenu.map((item) => (
                    <MenuItem key={item.path} onClick={(e) => handleLinkClick(e, item.path)} sx={{ pl: 4 }}>
                      <p className='font-medium'>{item.title}</p>
                    </MenuItem>
                  ))}
                  <MenuItem onClick={(e) => handleLinkClick(e, '/gallery')}>
                    <p className='font-medium'>Gallery</p>
                  </MenuItem>
                  <MenuItem onClick={(e) => handleLinkClick(e, '/contact')}>
                    <p className='font-medium'>Contact Us</p>
                  </MenuItem>
                </Box>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;