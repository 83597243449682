import React from "react";
import ServiceCard from "../cards/ServiceCard";
import TotalProjects from "../TotalProjects";

const Services2 = [
  {
    image: "../assets/images/2service.svg",
    name: "Export",
    details:
      "Streamlining global trade of farm products, guaranteeing standards and adherence.",
  },
  {
    image: "../assets/images/3service.svg",
    name: "Training",
    details:
      "Providing specialized training programs to enhance the skills and knowledge of agricultural professionals.",
  },
];

const Services3 = [
  {
    image: "../assets/images/4service.svg",
    name: "Irrigation EPC Project",
    details:
      "Engineering, procurement, and construction of efficient and sustainable irrigation systems tailored to needs.",
  },
  {
    image: "../assets/images/5service.svg",
    name: "High Tech Nursery",
    details:
      "Developing advanced nurseries with the latest technology for superior plant growth and productivity.",
  },
];

const Services4 = [
  {
    image: "../assets/images/6service.svg",
    name: "End to End Solutions for Agro Commodity",
    details:
      "Offering comprehensive financial support and consultancy for successful import and export projects globally.",
  },
  {
    image: "../assets/images/7service.svg",
    name: "International Placements",
    details:
      "Connecting global talent with agricultural opportunities to foster international growth and expertise.",
  },
  {
    image: "../assets/images/8service.svg",
    name: "Import / Export Project Finance Support",
    details:
      "Offering financial support and consultancy for successful import and export projects globally.",
  },
  {
    image: "../assets/images/turnkey.svg",
    name: "Turnkey Project",
    details:
      "Managing complete agricultural projects from start to finish. Our team ensures seamless integration of technology, resources, and processes.",
  },
];

const HomeServices = () => {
  return (
    <div>
      <div className="sm:mt-8 md:mt-[112px] lg:mt-[112px] px-[20px] sm:px-[30px] md:px-[40px] 2xl:px-[120px]">
        <div className="flex flex-col gap-[32px]">
          <div className="flex justify-center flex-col items-center mb-8">
            <h1 className="text-[32px] sm:text-[36px] md:text-[48px] font-bold text-center">Services</h1>
            <p className="text-lg text-center">
              Empowering Sustainable Farming with Expert Solutions
            </p>
          </div>
          <div className="flex flex-col lg:flex-row justify-between gap-[32px]">
            <div className="w-full lg:w-1/2 flex">
              <ServiceCard
                name="International Business Management Consulting"
                image="../assets/images/1service.svg"
                details="Navigating international markets with strategic consulting to enhance global business operations. Our experts provide tailored strategies to help your business thrive globally, ensuring compliance and competitive advantage."
              />
            </div>
            <div className="flex flex-col w-full lg:w-1/2 gap-[32px]">
              <div className="flex flex-col md:flex-row  gap-[32px]">
                {Services2.map((data, index) => (
                  <div key={index} className="w-full md:w-1/2 flex">
                    <ServiceCard data={data} />
                  </div>
                ))}
              </div>
              <div className="flex flex-col md:flex-row gap-[32px]">
                {Services3.map((data, index) => (
                  <div key={index} className="w-full md:w-1/2 flex gap-[32px]">
                    <ServiceCard data={data} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-4 gap-[32px]">
              {Services4.map((service, index) => (
                <ServiceCard key={index} data={service} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <TotalProjects />
    </div>
  );
};

export default HomeServices;
