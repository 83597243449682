import React from "react";
import FirstService from "../../component/services/FirstService";
import Keyproduct from "../../component/services/Keyproduct";
import ServiceCard from "../../component/services/ServiceCard";
import Corousel from "../Corousel";
import Faq from "../../component/services/Faq";
import CallToAction from "../../component/services/CallToAction";
const data = [
  {
    firstName: "Unlock Your Team’s Potential with Expert Training Solutions",
    firstDetail:
      "Customized Training Programs to Enhance Skills and Drive Success",
    firstbutton: "Get Started",
    firstlogodetail:
      "",
    logo1: "",
    logo2: "",
    logo3: "",
    firstImage: "../assets/images/services/training1.svg",
    secondName: "Why Choose Our Training Services?",
    secondDetail:
      "At Teximass Singapore private limited, we understand that effective training is key to organizational success. Our comprehensive training services are tailored to meet the unique needs of your business, ensuring that your team gains the skills and knowledge required to excel. From customized program development to expert delivery and ongoing support, we are committed to helping your organization thrive.",
    secondbutton: "Request a Quote",
    secondhead: "",
    secondImage: "../assets/images/services/training2.svg",
  },
];

const keyData = [
  {
    name: "Our Training Programs",
    detail:
      "Teximass Singapore Private Limited offers tailored training solutions to meet diverse client needs. Our expert trainers deliver customized programs using the latest technology for impactful learning, both onsite and online, enhancing skills and driving success.",
    header: "",
    button: "Request a Quote",
  },
];

const serviceCardData = [
  {
    logo: "../assets/images/services/CustomNeeds.svg",
    name: "Custom Training Programs to Meet Your Needs",
    detail:
      "We develop tailored training programs that align with your organization’s specific goals and objectives. Our process includes designing a comprehensive curriculum, selecting effective training methodologies, and creating detailed course materials to ensure a successful learning experience.",
    read: "",
  },
  {
    logo: "../assets/images/services/EngagingExperts.svg",
    name: "Engaging Training Delivered by Experts",
    detail:
      "Our expert trainers conduct interactive workshops, seminars, and training sessions either onsite or through virtual platforms. We utilize a mix of lectures, discussions, activities, and simulations to create a dynamic and engaging learning environment.",
    read: "",
  },
  {
    logo: "../assets/images/services/FocusedLevels.svg",
    name: "Focused Skill Development for All Levels",
    detail:
      "Our training programs focus on enhancing specific skills and competencies needed for professional success. We offer training for all organizational levels, from frontline employees to executives, ensuring comprehensive development across your team.",
    read: "",
  },
  {
    logo: "../assets/images/services/ExpertGaps.svg",
    name: "Expert Consulting to Identify and Bridge Skill Gaps",
    detail:
      "Our consulting services help assess your organizational needs, identify skill gaps, and recommend tailored training solutions. Our holistic approach ensures your team receives the most relevant and effective training to meet your business objectives.",
    read: "",
  },
  {
    logo: "../assets/images/services/AdvancedLearning.svg",
    name: "Advanced Technology for Modern Learning",
    detail:
      "We leverage cutting-edge technology to deliver training, including e-learning platforms, virtual classrooms, and interactive tools. This enhances engagement, accessibility, and the overall effectiveness of the training, making it more convenient and impactful.",
    read: "",
  },
  {
    logo: "../assets/images/services/ContinuousEvaluation.svg",
    name: "Continuous Improvement Through Evaluation",
    detail:
      "We measure the effectiveness of our training programs through detailed participant feedback, assessments, and post-training evaluations. Our follow-up support includes additional coaching and resources to reinforce learning outcomes and ensure lasting benefits for your organization.",
    read: "",
  },
];

const testimonials = [
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
];

const clientDeatil = [
  {
    name: "Client Testimonials",
    header: "Hear From Our Satisfied Clients",
    detail:
      "Discover how our training services have helped other organizations achieve their goals. Read testimonials from our satisfied clients to see the impact of our customized training programs.",
  },
];

const corouselData = [
  {
    que: "What types of training programs do you offer?",
    ans:
      "Our training sessions are delivered through interactive workshops, seminars, and virtual platforms. We provide both onsite and online training to suit your preferences.",
  },
  {
    que: "How are the training sessions delivered?",
    ans:
      "Our training sessions are delivered through interactive workshops, seminars, and virtual platforms. We provide both onsite and online training to suit your preferences.",
  },
  {
    que: "Who conducts the training sessions?",
    ans:
      "We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    que: "Can you help us identify skill gaps and recommend training solutions?",
    ans:
      "We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    que: "How do you measure the effectiveness of your training programs?",
    ans:
      "We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
];

const callAction= [{
  name:"Partner with Teximass for Expert Training Solutions",header:"Elevate Your Team’s Skills with Customized Training",detail:"Enhance your team’s skills and drive organizational success with our tailored training programs. Contact us today to learn more about our training services and how we can help you achieve your professional development goals.",button:"Contact Us Today"
}]

const Training = () => {
  return (
    <>
      <div className="px-[20px] md:px-[40px]">
        <FirstService data={data} />
        <Keyproduct data={keyData} />
        <ServiceCard data={serviceCardData} />
        <Corousel testimonials={testimonials} clientDeatil={clientDeatil} />
        <Faq corouselData={corouselData} />
      </div>
      <CallToAction data={callAction}/>
    </>
  );
};

export default Training;
