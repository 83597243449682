import React from "react";

const FirstService = ({ data }) => {
  const datas = data[0];
  return (
    <div className="mt-[200px] mb-[112px]">
      <div className="flex flex-col-reverse md:flex-row gap-[16px] md:gap-[100px] mb-[110px] md:mb-[180px]">
        <div className="flex flex-col w-full lg:w-1/2 align-middle justify-center">
          <div className="">
            <h1 className="text-[30px] md:text-[48px] font-bold leading-[120%] md:leading-[48px] ">
              {datas?.firstName}
            </h1>
            <p className="mt-[8px] mb-[16px] mb:text-[18px] font-normal">{datas?.firstDetail}</p>
            <button className="bg-[#41B641] text-[#FFFFFF] px-6 py-2 rounded-full mb-8">
              <h1>{datas?.firstbutton}</h1>
            </button>
          </div>
          <div className="flex flex-col gap-[16px]">
            <p>{datas?.firstlogodetail}</p>
            <div className="flex flex-row ">
              <img className="w-[100px]" src={datas?.logo1} alt="" />
              <img className="w-[100px]" src={datas?.logo2} alt="" />
              <img className="w-[100px]" src={datas?.logo3} alt="" />
            </div>
          </div>
        </div>
        <div className="flex w-full lg:w-1/2 md:justify-center">
          <img src={datas?.firstImage} alt="" />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-[16px] md:gap-[100px]">
        <div className="flex w-full lg:w-1/2 md:justify-center">
          <img src={datas?.secondImage} alt="" />
        </div>
        <div className="flex flex-col w-full lg:w-1/2 align-middle justify-center">
          <div className="">
            <p className="text-[16px] md:text-[18px] font-semibold mb-2 md:mb-0">
              {datas?.secondhead}
            </p>
            <h1 className="text-[30px] md:text-[48px] font-bold leading-[120%] md:leading-[48px] ">
              {datas?.secondName}
            </h1>
            <p className="my-6 text-[16px] md:text-[18px] font-normal">
              {datas?.secondDetail}
            </p>
            <button className="bg-[#41B641] text-[#FFFFFF] px-6 py-2 rounded-full mb-8">
              <h1>{datas?.secondbutton}</h1>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstService;
