import React from "react";
import FirstService from "../../component/services/FirstService";
import Keyproduct from "../../component/services/Keyproduct";
import ServiceCard from "../../component/services/ServiceCard";
import Corousel from "../Corousel";
import Faq from "../../component/services/Faq";
import CallToAction from "../../component/services/CallToAction";
const data = [
  {
    firstName: "Global Export Services for Sustainable Agriculture",
    firstDetail:
      "Delivering high-quality agricultural products and equipment worldwide with a focus on sustainability and innovation.",
    firstbutton: "Request a Quote",
    firstlogodetail:
      "Our Suppliers are certified by the highest industry standards.",
    logo1: "../assets/images/services/BRC.svg",
    logo2: "../assets/images/services/IFS.svg",
    logo3: "../assets/images/services/FSSC.svg",
    firstImage: "../assets/images/services/apple.svg",
    secondName: "Streamlined Logistics with a Global Reach",
    secondDetail:
      "At Teximass Singapore private limited, we prioritize timely and reliable delivery through efficient maritime transport. Our strategic locations in Ethiopia and Dubai enable us to serve key markets effectively, streamline operations, and reduce transit times. This ensures we meet the diverse needs of our clients in the African and Middle Eastern regions with unmatched efficiency.",
    secondbutton: "Request a Quote",
    secondhead: "Our Logistics Strategy",
    secondImage: "../assets/images/services/shipport.svg",
  },
];

const keyData = [
  {
    name: "Our Key Products",
    detail:
      "Teximass Singapore private limited offers a diverse range of high-quality agricultural products and equipment designed to meet the needs of our global clients. Our commitment to sustainability and innovation ensures that we deliver only the best to our customers.",
    header: "",
    button: "Request a Quote",
  },
];

const serviceCardData = [
  {
    logo: "../assets/images/services/Organic.svg",
    name: "Organic",
    name2:"Food",
    detail:
      "Exporting fresh, organic food products that adhere to the highest quality standards. Our range includes fruits, vegetables, grains, and other food items grown without synthetic chemicals. We ensure they are nutritious, safe for consumption, and environmentally friendly, supporting sustainable farming practices.",
    // read: "Read more",
  },
  {
    logo: "../assets/images/services/Medicinal.svg",
    name: "Medicinal ",
    name2:"Plants",
    detail:
      "Providing plants known for their therapeutic benefits, including turmeric for its anti-inflammatory and antioxidant properties, ginger for its digestive and anti-nausea effects, and aloe vera for its skin-healing properties. These plants are cultivated under strict quality standards to ensure their efficacy and safety for various health applications, promoting natural wellness.",
    // read: "Read more",
  },
  {
    logo: "../assets/images/services/Biotechnological.svg",
    name: "Biotechnological ",
    name2:"Products",
    detail:
      "Offering innovative biotechnological solutions for agriculture and industry. Our products include biopharmaceuticals like medications produced using biotechnology methods, genetically modified crops with enhanced pest resistance and nutritional content, and industrial enzymes used in food processing, textile manufacturing, and biofuel production.",
    // read: "Read more",
  },
  {
    logo: "../assets/images/services/Tissue.svg",
    name: "Tissue Culture ",
    name2:"Plants",
    detail:
      "Supplying high-quality tissue culture plants known for their resilience and productivity. Our selection includes banana, teakwood, guava, and strawberry plants, all cultivated under sterile conditions to ensure the best quality and health. These plants support sustainable agricultural practices and help improve crop yields, contributing to food security.",
    // read: "Read more",
  },
  {
    logo: "../assets/images/services/Fresh.svg",
    name: "Fresh Fruits and ",
    name2:"Vegetables",
    detail:
      "Sourcing a variety of fresh produce directly from farmers to ensure the highest quality. Our selection includes vegetables such as chauli, white pumpkin, brinjal, and beetroot, as well as fruits like apple, avocado, banana, coconut, and custard apple. We are committed to delivering nutritious and fresh produce that supports healthy diets and enhances well-being.",
    // read: "Read more",
  },
  {
    logo: "../assets/images/services/Agricultural.svg",
    name: "Agricultural Products & ",
    name2:"Irrigation Equipment",
    detail:
      "Supplying essential farming products and advanced irrigation systems to enhance productivity and sustainability. Our range includes high-quality seeds, fertilizers, crop protection solutions, and efficient irrigation equipment designed to support sustainable farming practices. We ensure optimal water usage and help farmers achieve better yields.",
    // read: "Read more",
  },
];

const testimonials = [
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
];

const clientDeatil = [
  {
    name: "Client Testimonials",
    header: "Hear From Our Satisfied Clients",
    detail:
      "Discover how our export services have helped businesses around the world achieve their goals. Read testimonials from our clients to see the impact of our high-quality products and reliable service.",
  },
];

const corouselData = [
  {
    que: "Which regions do you serve?",
    ans:
      " We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    que: "What products does Teximass Singapore private limited export?",
    ans:
      " We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    que: "How do you ensure the quality of your exported products?",
    ans:
      " We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    que: "What shipping methods do you use for exports?",
    ans:
      " We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    que: "How can I get in touch with your export team for inquiries?",
    ans:
      " We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
];

const callAction= [{
  name:"Partner with Teximass for Reliable and High-Quality Export Services",header:"Ready to Streamline Your Export Operations?",detail:"Take the next step in optimizing your supply chain and expanding your global reach. Contact us today to learn more about our export services and how we can support your business success.",button:"Contact Us Today"
}]
const Export = () => {
  return (
    <>
      <div className="px-[20px] sm:px-[30px] md:px-[40px] 2xl:px-[120px] ">
        <FirstService data={data} />
        <Keyproduct data={keyData} />
        <ServiceCard data={serviceCardData} />
        <Corousel testimonials={testimonials} clientDeatil={clientDeatil} />
        <Faq corouselData={corouselData} />
      </div>
      <CallToAction data={callAction}/>
    </>
  );
};

export default Export;
