import React from 'react';
import GalleryMainCard from './cards/GalleryMainCard';

const gallery1 = [
  { image: "./assets/images/gallery1.svg", name: "Innovative Agriculture Practices", detail: "Discover how we’re transforming agriculture with sustainable and innovative techniques." },
  { image: "./assets/images/gallery2.svg", name: "Pioneering Carbon Credit Solutions", detail: "See how our projects contribute to reducing carbon footprints and promoting sustainability." },
  { image: "./assets/images/gallery3.svg", name: "Advanced Tissue Culture Labs", detail: "Get a glimpse inside our cutting-edge labs where we cultivate superior plant varieties." },
  { image: "./assets/images/gallery4.svg", name: "Sterile tissue Culture Clean Rooms", detail: "Explore our state-of-the -are clean rooms designed for optimal plant tissue culture." },
];

const ImpactGallery = () => {
  return (
    <div className='my-[60px] md:my-[112px] px-[20px] sm:px-[30px] md:px-[40px] 2xl:px-[120px] '>
      <div>
        <h1 className='text-3xl font-bold text-start mb-4'>Gallery</h1>
        <p className='text-lg text-start mb-12'>See Our Impact in Action</p>
      </div>
      <div className='flex flex-col justify-center mt-[50px] gap-[32px]'>
        <div className='flex flex-col md:flex-row w-full gap-[32px]'>
          <GalleryMainCard 
            data={gallery1[0]} 
            className='w-full md:w-[60%]'
          />
          <GalleryMainCard 
            data={gallery1[1]} 
            className='w-full md:w-[40%]'
          />
        </div>
        <div className='flex flex-col md:flex-row w-full gap-[32px]'>
          <GalleryMainCard 
            data={gallery1[2]} 
            className='w-full md:w-[40%]'
          />
          <GalleryMainCard 
            data={gallery1[3]} 
            className='w-full md:w-[60%]'
          />
        </div>
      </div>
    </div>
  );
};

export default ImpactGallery;
