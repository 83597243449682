import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className="bg-[#FFFAEE] px-8 py-12">
      <div className="max-w-7xl mx-auto">
        <header className="mb-12">
          <img
            src="../assets/images/Logo.svg"
            alt="TEXIMASS SINGAPORE PTE LTD"
            className="w-64"
          />
        </header>

        <main className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mb-12">
          <div className="">
            <h2 className="font-bold mb-4">About Us</h2>
            <ul className="flex flex-col gap-[8px] text-sm text-[#666666]">
              <Link to="/about"><li>About Company</li></Link>
              <Link to="/about"><li>Meet Our Team</li></Link>
              <Link to="/about"><li>Locations</li></Link>
            </ul>
          </div>

          <div>
            <h2 className="font-bold mb-4">Services</h2>
            <ul className=" text-sm text-[#666666] flex flex-col gap-[8px]">
            <Link to="/export"><li>Export</li></Link>
            <Link to="/training"><li>Training</li></Link>
            <Link to="/business"><li>International Business Management</li></Link>
            <Link to="/nursery"><li>Consulting</li></Link>
            <Link to="/nursery"><li>High Tech Nursery</li></Link>
            <Link to="/placement"><li>International Placements</li></Link>
            <Link to="/finance"><li>Import / Export Project Finance Support</li></Link>
            <Link to="/"><li>End to End Solutions for Agro Commodity</li></Link>
            <Link to="/"><li>Irrigation EPC Project</li></Link>
            <Link to="/"><li>Turnkey Project</li></Link>
            </ul>
          </div>

          <div>
            <h2 className="font-bold mb-4">Gallery</h2>
            <ul className="flex flex-col gap-[8px] text-sm text-[#666666]">
              <Link to="/gallery"><li>Innovative Agriculture Practices</li></Link>
              <Link to="/gallery"><li>Pioneering Carbon Credit Solutions</li></Link>
              <Link to="/gallery"><li>Advanced Tissue Culture Labs</li></Link>
              <Link to="/gallery"><li>Sterile Tissue Culture Clean Rooms</li></Link>
            </ul>
          </div>

          <div>
            <h2 className="font-bold mb-4">Follow Us</h2>
            <ul className="space-y-2 text-sm text-[#666666]">
              <li className="">
                <img
                  src="../assets/images/fb.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />{" "}
                Facebook
              </li>
              <li>
                <img
                  src="../assets/images/insta.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />{" "}
                Instagram
              </li>
              <li>
                <img
                  src="../assets/images/X.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />{" "}
                X
              </li>
              <li>
                <img
                  src="../assets/images/linkdin.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />{" "}
                LinkedIn
              </li>
              <li>
                <img
                  src="../assets/images/youtube.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />{" "}
                Youtube
              </li>
            </ul>
          </div>
        </main>

        <div className="mb-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h2 className="font-bold mb-4">Contact Us</h2>
              <p className="text-sm mb-4 text-[#666666]">Contact Teximass</p>
              <div className="flex flex-col">
                <p className="text-[16px font-semibold]">Email:</p>
                <div className="flex items-center">
                  <img
                    src="../assets/images/mail.svg"
                    alt=""
                    className="w-4 h-4 inline mr-2"
                  />
                  <p className="text-[14px]">info@Teximass.com</p>
                </div>
              </div>
            </div>
            <div>
              <h3 className="font-semibold mb-2 text-[14px]">Singapore:</h3>
              <div className="flex flex-row gap-[8px] mb-[8px]">
                <img
                  src="../assets/images/location.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />
                <p className="text-[14px]"> TEXIMASS SINGAPORE PTE LTD
                <br />
                89 Short Street, <br/>04_09
                Golden Wall Centre
                <br />
                Singapore - 199128</p>
              </div>
              <div className="flex flex-row gap-[8px]">
                <img
                  src="../assets/images/call.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />
                <p className="text-[14px]">+65 9154 9715</p>
              </div>
            </div>
            <div>
              <h3 className="font-semibold mb-2 text-[14px]">India:</h3>
              <div className="flex flex-row gap-[8px]">
                <img
                  src="../assets/images/location.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />
                <p className="text-[14px]"> TEXIMASS INDIA PVT LTD <br/>
                16LH804, Lanco
                Hills, Khajaguda, Manikonda,
                Hyderabad,
                Telangana,<br/> India - 500089</p>
              </div>
              <div className="flex flex-row gap-[8px] mt-[8px]">
                <img
                  src="../assets/images/call.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />
                <p className="text-[14px]">+91 8008371369</p>
              </div>
            </div>
            <div>
              <h3 className="font-semibold mb-2 text-[14px]">Dubai:</h3>
              <div className="flex flex-row gap-[8px]">

                <img
                  src="../assets/images/location.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />
                <p className="text-[14px]">
                TEXIMASS GLOBAL - FZCO
                <br />
                Building A1, Dubai Digital Park, 
                Silicon Oasis, Dubai,<br/>
                United Arab Emirates
                </p>
              </div>
                <div className="flex flex-row gap-[8px] mt-[8px]">
                <img
                  src="../assets/images/call.svg"
                  alt=""
                  className="w-4 h-4 inline mr-2"
                />
                <p className="text-[14px]">+65 9154 9715</p>
                </div>
            </div>
            
          </div>
        </div>

        {/* <hr className="mt-[40px] mb-[33px] h-[6px] py-10" /> */}
        <div className="mt-[40px] mb-[33px] h-[1px] bg-[#141414]" />
        <footer className="flex flex-col  md:flex-row justify-between text-xs text-center">
          <p className="mb-2">© 2024 Teximass. All rights reserved.</p>
          <div>
            <a href="#" className="hover:underline mr-4">
              Privacy Policy
            </a>
            <a href="#" className="hover:underline mr-4">
              Terms of Service
            </a>
            <a href="#" className="hover:underline">
              Cookies Settings
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default HomePage;
