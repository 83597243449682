import React from "react";
import FirstService from "../../component/services/FirstService";
import Keyproduct from "../../component/services/Keyproduct";
import ServiceCard from "../../component/services/ServiceCard";
import Corousel from "../Corousel";
import Faq from "../../component/services/Faq";
import CallToAction from "../../component/services/CallToAction";
const data = [
  {
    firstName: "Empowering Your Global Trade with Expert Finance Support",
    firstDetail:
      "Comprehensive Solutions for Import and Export Projects",
    firstbutton: "Get Financial Support",
    firstlogodetail:
      "",
    logo1: "",
    logo2: "",
    logo3: "",
    firstImage: "../assets/images/services/finance1.svg",
    secondName: "Your Success is Our Priority",
    secondDetail:
      "At Teximass Singapore private limited, we understand that our clients are the key to our success. With extensive expertise in import and export finance, we offer robust financial support through our trusted partners, ensuring your projects are well-funded and set for success.",
    secondbutton: "Request a Quote",
    secondhead: "Why Choose Us?",
    secondImage: "../assets/images/services/finance2.svg",
  },
];

const keyData = [
  {
    name: "What We Offer",
    detail:
      "We provide comprehensive finance support for a wide range of import and export projects. Our services are designed to help you overcome financial hurdles and achieve your business goals.",
    header: "Tailored Solutions to Solve Your Challenges",
    button: "Request a Quote",
  },
];

const serviceCardData = [
  {
    logo: "../assets/images/services/Turmeric.svg",
    name: "Export",
    detail:
      `Turmeric, mango pulp, guava pulp, banana tissue culture plants, sugar, African and Middle East Countries.
      1.Organic Food
      2.Medicinal Plants
      3.Biotechnological Products
      4.Tissue Culture Plants
      5.Fresh Fruits and Vegetables`,
    read: "",
  },
  {
    logo: "../assets/images/services/Agro.svg",
    name: "Agro Processing Plant Proposal",
    detail:
      "The company is currently conducting a feasibility study of starting a plant and manufacturing unit to procure and process various agriculture products like Jalapenos, Cauliflower, Green chilies, Red Capsicum, Green Capsicum, Sweet Corn, Ginger, Strawberries, and Jackfruit etc. The proposed start-of-the-art processing plant will have an installed capacity of 10,000 TPA.",
    read: "",
  },
];

const testimonials = [
  {
    rating: 5,
    text: "The training provided by Teximass was transformative for our team. The customized programs and expert delivery made a significant difference in our performance.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
  {
    rating: 5,
    text: "Teximass provided exceptional consultancy that transformed our agricultural practices, leading to increased efficiency and sustainability.",
    name: "John Doe",
    title: "CEO, Green Farms",
    image: "../assets/images/CEO.svg",
  },
];

const clientDeatil = [
  {
    name: "Client Testimonials",
    header: "Hear From Our Satisfied Clients",
    detail:
      "Discover how our training services have helped other organizations achieve their goals. Read testimonials from our satisfied clients to see the impact of our customized training programs.",
  },
];

const corouselData = [
  {
    name: "What types of training programs do you offer?",
    detail:
      "Answer: Our training sessions are delivered through interactive workshops, seminars, and virtual platforms. We provide both onsite and online training to suit your preferences.",
  },
  {
    name: "How are the training sessions delivered?",
    detail:
      "Answer: Our training sessions are delivered through interactive workshops, seminars, and virtual platforms. We provide both onsite and online training to suit your preferences.",
  },
  {
    name: "Who conducts the training sessions?",
    detail:
      "Answer: We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    name: "Can you help us identify skill gaps and recommend training solutions?",
    detail:
      "Answer: We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
  {
    name: "How do you measure the effectiveness of your training programs?",
    detail:
      "Answer: We export a wide range of agricultural products including organic food, medicinal plants, biotechnological products, tissue culture plants, and fresh fruits and vegetables.",
  },
];

const callAction= [{
  name:"Partner with Visual Organic Agrotech for Expert Finance Solutions",header:"Ready to Secure Financial Support for Your Project?",detail:"Take the next step in securing the financial support you need for your import and export projects. Contact us today to learn more about our services and how we can help you achieve your business objectives.",button:"Contact Us Today"
}]

const Finance = () => {
  return (
    <>
      <div className="px-[20px] md:px-[40px]">
        <FirstService data={data} />
        <Keyproduct data={keyData} />
        <ServiceCard data={serviceCardData} />
        {/* <Corousel testimonials={testimonials} clientDeatil={clientDeatil} /> */}
        {/* <Faq corouselData={corouselData} /> */}
      </div>
      <CallToAction data={callAction}/>
    </>
  );
};

export default Finance;
