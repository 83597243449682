import React from 'react'
import VerticalTabs from './gallery/GalleryTabs'
import CustomCourosel from '../component/CustomAccordation'

const Gallery = () => {
  return (
    <div className='mt-[200px] px-[20px] sm:px-[30px] md:px-[40px] 2xl:px-[120px]  flex flex-col gap-[70px]'>
      <div className=' w-full md:w-3/4'>
        <p className='text-[16px] font-semibold mb-4'>Gallery</p>
        <h1 className='text-[30px] md:text-[48px] font-bold mb-6'>Explore our journey through images</h1>
        <p className='text-[18px] font-normal'>From innovative projects to collaborative events, our gallery showcases the dedication and expertise that drive our mission in promoting sustainability and innovation in agriculture.</p>
      </div>
      <VerticalTabs/>
    </div>
  )
}

export default Gallery