import React, { useState } from 'react';

const Contact = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="px-[20px] sm:px-[30px] md:px-[40px] 2xl:px-[120px]  mt-[150px] mb-[110px] md:mb-[110px] md:my-[200px]">
      <div className="flex flex-col lg:flex-row justify-between align-middle items-center">
        <div className="w-full  lg:w-1/2 mb-8 md:mb-0">
          <h2 className="text-sm font-semibold mb-2">We're Here to Help</h2>
          <h1 className="text-4xl font-bold my-4">Contact us</h1>
          <p className="mb-6 md:pr-[8rem]">
            Reach out to Teximass Singapore Pte Ltd for any inquiries, support, or collaboration opportunities.
            Our team is ready to assist you with your needs.
          </p>
          <div className="flex items-center my-[20px] md:my-[40px]">
                  <img
                    src="../assets/images/mail.svg"
                    alt=""
                    className="w-4 h-4 inline mr-2"
                  />
                  <p className="text-[16px] font-normal underline">info@Teximass.com</p>
          </div>
          <div>
            {/* singapore */}
            <div className="flex flex-col mb-[40px]">
              <h1 className="text-[24px] font-semibold mb-[16px]">Singapore</h1>
              <div className="flex align-center gap-[18px] mb-[8px]">
                <div>
                  <img
                    src="./assets/images/locationIcon.svg"
                    alt=""
                    className="mt-1 "
                  />
                </div>
                <div>
                <p className="text-[18px] font-normal"> TEXIMASS SINGAPORE PTE LTD
                <br />
                89 Short Street, <br/>04_09
                Golden Wall Centre
                <br />
                Singapore - 199128</p>
                </div>
              </div>
             <div className="flex flex-col  align-center gap-[16px]">
                <div className="flex gap-[11px]">
                  
                  <div>
                  </div>
                </div>
                <div className="flex gap-[11px]">
                  <div>
                    <img
                      src="./assets/images/callicon.svg"
                      alt=""
                      className="mt-1 "
                    />
                  </div>
                  <div>
                    <p className="text-[18px] font-normal">
                      +65 9154 9715
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* india */}
            <div className="flex flex-col mb-[40px]">
              <h1 className="text-[24px] font-semibold mb-[16px]">India</h1>
              <div className="flex align-center gap-[20px] mb-[8px]">
                <div>
                  <img
                    src="./assets/images/locationIcon.svg"
                    alt=""
                    className="mt-1 "
                  />
                </div>
                <div>
                <p className="text-[18px] font-normal"> TEXIMASS INDIA PVT LTD <br/>
                16LH804, Lanco
                Hills, Khajaguda, Manikonda,
                Hyderabad,
                Telangana,<br/> India - 500089</p>
                </div>
              </div>
              <div className="flex flex-col  align-center gap-[8px]">
                <div className="flex gap-[11px]">
                  
                  <div>
                  </div>
                </div>
                <div className="flex gap-[11px]">
                  <div>
                    <img
                      src="./assets/images/callicon.svg"
                      alt=""
                      className="mt-1 "
                    />
                  </div>
                  <div>
                    <p className="text-[18px] font-normal">
                      +91 8008371369
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* dubai */}
            <div className="flex flex-col mb-[40px]">
              <h1 className="text-[24px] font-semibold mb-[16px]">Dubai</h1>
              <div className="flex align-center gap-[20px] mb-[8px]">
                <div>
                  <img
                    src="./assets/images/locationIcon.svg"
                    alt=""
                    className="mt-1 "
                  />
                </div>
                <div>
                <p className="text-[18px] font-normal">
                TEXIMASS GLOBAL - FZCO
                <br />
                Building A1, Dubai Digital Park, 
                Silicon Oasis, Dubai,<br/>
                United Arab Emirates
                </p>
                </div>
              </div>
              <div className="flex flex-col  align-center gap-[8px]">
                <div className="flex gap-[11px]">
                  
                  <div>
                  </div>
                </div>
                <div className="flex gap-[11px]">
                  <div>
                    <img
                      src="./assets/images/callicon.svg"
                      alt=""
                      className="mt-1 "
                    />
                  </div>
                  <div>
                    <p className="text-[18px] font-normal">
                      +65 9154 9715
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className="w-full  lg:w-1/2">
          <form id="submitforms" className="space-y-4 md:pr-[6rem]">
            <div>
              <label htmlFor="name" className="block mb-1">Name</label>
              <input type="text" id="name" name="name" className="w-full p-2 border border-[#333333] rounded" required/>
            </div>
            <div>
              <label htmlFor="email" className="block mb-1">Email</label>
              <input type="email" id="email" name="email" className="w-full p-2 border rounded border-[#333333]" required/>
            </div>
            <div>
              <label htmlFor="message" className="block mb-1">Message</label>
              <textarea id="message" name="message" rows="8" className="w-full p-2 border rounded border-[#333333]" placeholder="Type your message..." required></textarea>
            </div>
            <div className="flex items-center">
              <label htmlFor="terms" className="flex items-center cursor-pointer">
                <div className="relative">
                  <input 
                    type="checkbox" 
                    id="terms" 
                    name="terms" 
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="sr-only" 
                  />
                  <div className={`w-5 h-5 border-2 rounded transition-colors ${
                    isChecked ? 'bg-[#41B641] border-[#41B641]' : 'bg-white border-gray-300'
                  }`}>
                    {isChecked && (
                      <div className=' mt-[1px]'>
                        <img src='./assets/images/sign.svg' alt=''/>
                      </div>
                    )}
                  </div>
                </div>
                <span className="ml-2 text-sm">I accept the Terms</span>
              </label>
            </div>
            <button 
              type="submit" 
              className={`px-6 py-2 rounded-full ${
                isChecked ? 'bg-[#41B641] text-white hover:bg-green-600' : 'bg-gray-400 text-gray-700 cursor-not-allowed'
              }`}
              disabled={!isChecked}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
