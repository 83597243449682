import React, { useState } from "react";

const AboutLocation = () => {
  const [activeMap, setActiveMap] = useState("singapore");
  return (
    <div className="flex flex-col justify-center my-[112px] align-middle items-center px-[20px] sm:px-[30px] md:px-[40px]">
      <div className="gap-[16px] flex flex-col justify-center align-middle items-center text-center w-full md:2/3 lg:w-1/2">
        <p className="text-[48px] font-bold">Our Locations</p>
        <p className="">
          With a global presence, Teximass Singapore Pte Ltd is dedicated to
          serving clients worldwide. Visit us at one of our locations to learn
          more about how we can help you achieve your agricultural goals.
        </p>
      </div>
      <div className="flex flex-col lg:flex-row w-full lg:h-[1050px] mt-[112px] gap-[32px] ">
        <div className="flex w-full lg:w-2/3 h-fit">
        {activeMap === "singapore" && (
    <iframe
      className="w-full h-[300px] sm:h-[400px] md:h-[600px] lg:h-[1050px]"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      src="https://maps.google.com/maps?width=100%25&amp;height=474&amp;hl=en&amp;q=Level%201,%2012%20Sample%20Address,%20Singapore%20556083+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    ></iframe>
  )}
  {activeMap === "dubai" && (
    <iframe 
      className="w-full h-[300px] sm:h-[400px] md:h-[600px] lg:h-[1050px]"
      frameborder="0" 
      scrolling="no"
      marginheight="0" 
      marginwidth="0" 
      src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=TEXIMASS%20GLOBAL%20-%20FZCO%20IFZA%20Property%20FZCO%20Building%20A1,%20Dubai%20Digital%20Park,%20Dubai%20Silicon%20Oasis,%20Dubai,United%20Arab%20Emirates+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    ></iframe>
  )}
  {activeMap === "india" && (
   <iframe
    className="w-full h-[300px] sm:h-[400px] md:h-[600px] lg:h-[1050px]"
    frameborder="0"
     scrolling="no" 
     marginheight="0" 
     marginwidth="0" 
     src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=DESMI%20India%20Private%20Limited,%20No.519,%20IP%20Nadergul,%20Nadergul%20Village,%20Saroornagar,%20Telangana%20501510+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
     </iframe>
  )}
        </div>
        <div className="flex flex-col w-full lg:w-1/3 gap-[40px] justify-center">
          <div>
            <div className="flex flex-col mb-[40px]">
            <h1 className="text-[24px] font-bold">Email</h1>
            {/* <p className="text-[18px] font-normal underline">
                      info@Teximass.com
            </p> */}
            <div className="flex items-center">
                  <img
                    src="../assets/images/mail.svg"
                    alt=""
                    className="w-4 h-4 inline mr-2"
                  />
                  <p className="text-[14px] underline">info@Teximass.com</p>
                </div>
            </div>
            {/* singapore */}
            <div className="flex flex-col mb-[40px]">
              <h1 className="text-[24px] font-semibold mb-[16px]">Singapore</h1>
              <div className="flex align-center gap-[20px] mb-[8px]">
                <div>
                  <img
                    src="./assets/images/locationIcon.svg"
                    alt=""
                    className="mt-1 "
                  />
                </div>
                <div>
                <p className="text-[18px] font-normal"> TEXIMASS SINGAPORE PTE LTD
                <br />
                89 Short Street, <br/>04_09
                Golden Wall Centre
                <br />
                Singapore - 199128</p>
                </div>
              </div>
              <h1 className="text-[16px] font-semibold cursor-pointer underline" onClick={() => setActiveMap("singapore")}>View Map</h1>
              <div className="flex flex-col  align-center gap-[8px]">
                {/* <div className="flex gap-[12px]">
                  <div>
                    <img
                      src="./assets/images/mailicon.svg"
                      alt=""
                      className="mt-1 "
                    />
                  </div>
                  <div>
                    <p className="text-[18px] font-normal underline">
                      info@Teximass.com
                    </p>
                  </div>
                </div> */}
                <div className="flex gap-[12px]">
                  <div>
                    <img
                      src="./assets/images/callicon.svg"
                      alt=""
                      className="mt-1 "
                    />
                  </div>
                  <div>
                    <p className="text-[18px] font-normal">
                      +65 9154 9715
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* india */}
            <div className="flex flex-col mb-[40px]">
            <h1 className="text-[24px] font-semibold mb-[16px]">India</h1>
              <div className="flex align-center gap-[20px] mb-[8px]">
                <div>
                  <img
                    src="./assets/images/locationIcon.svg"
                    alt=""
                    className="mt-1 "
                  />
                </div>
                <div>
                <p className="text-[18px] font-normal"> TEXIMASS INDIA PVT LTD <br/>
                16LH804, Lanco
                Hills, Khajaguda, Manikonda,
                Hyderabad,
                Telangana,<br/> India - 500089</p>
                </div>
              </div>
              <h1 className="text-[16px] font-semibold cursor-pointer underline" onClick={() => setActiveMap("india")}>View Map</h1>
              <div className="flex flex-col  align-center gap-[8px]">
                {/* <div className="flex gap-[12px]">
                  <div>
                    <img
                      src="./assets/images/mailicon.svg"
                      alt=""
                      className="mt-1 "
                    />
                  </div>
                  <div>
                    <p className="text-[18px] font-normal underline">
                      info@Teximass.com
                    </p>
                  </div>
                </div> */}
                <div className="flex gap-[12px]">
                  <div>
                    <img
                      src="./assets/images/callicon.svg"
                      alt=""
                      className="mt-1 "
                    />
                  </div>
                  <div>
                    <p className="text-[18px] font-normal">
                      +91 8008371369
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* dubai */}
            <div className="flex flex-col mb-[40px]">
              <h1 className="text-[24px] font-semibold mb-[16px]">Dubai</h1>
              <div className="flex align-center gap-[20px] mb-[8px]">
                <div>
                  <img
                    src="./assets/images/locationIcon.svg"
                    alt=""
                    className="mt-1 "
                  />
                </div>
                <div>
                <p className="text-[18px] font-normal">
                TEXIMASS GLOBAL - FZCO
                <br />
                Building A1, Dubai Digital Park, 
                Silicon Oasis, Dubai,<br/>
                United Arab Emirates
                </p>
                </div>
              </div>
              <h1 className="text-[16px] font-semibold cursor-pointer underline" onClick={() => setActiveMap("dubai")}>View Map</h1>
              <div className="flex flex-col  align-center gap-[8px]">
                {/* <div className="flex gap-[12px]">
                  <div>
                    <img
                      src="./assets/images/mailicon.svg"
                      alt=""
                      className="mt-1 "
                    />
                  </div>
                  <div>
                    <p className="text-[18px] font-normal underline">
                      info@Teximass.com
                    </p>
                  </div>
                </div> */}
                <div className="flex gap-[12px]">
                  <div>
                    <img
                      src="./assets/images/callicon.svg"
                      alt=""
                      className="mt-1 "
                    />
                  </div>
                  <div>
                    <p className="text-[18px] font-normal">
                      +65 9154 9715
                    </p>
                  </div>
                </div>
              </div>
            </div>
           

            <div className="flex flex-col">
              <h1 className="text-[24px] font-semibold flex mb-[16px]">
                Follow Us
              </h1>
              <div className="flex gap-[8px] mb-2">
                <div className="flex gap-[8px]">
                  <img src="./assets/images/fb.svg" alt="" />
                  <p>Facebook</p>
                </div>
                <div className="flex gap-[8px]">
                  <img src="./assets/images/insta.svg" alt="" />
                  <p>Instagram</p>
                </div>
                <div className="flex gap-[8px] ">
                  <img src="./assets/images/linkdin.svg" alt="" />
                  <p>LinkedIn</p>
                </div>
              </div>
              <div className="flex gap-[8px]">
                <div className="flex gap-[8px]">
                  <img src="./assets/images/youtube.svg" alt="" />
                  <p>Youtube</p>
                </div>
                <div className="flex gap-[8px]">
                  <img src="./assets/images/x.svg" alt="" />
                  <p>X</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutLocation;
